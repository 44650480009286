import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
    <Layout sermonSrc="https://www.youtube.com/embed/Mvsrfr5XayY">
        <SEO title="Good Enough? - Crossing Rivers" />
    </Layout>
)

export default SermonPost
